import React, { useEffect } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Icon, LinkBox, Section } from "@quarkly/widgets";
import TagManager from 'react-gtm-module';
import WavedropperSignature from './WavedropperSignature';
import { FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
const tagManagerArgs = {
	gtmId: 'GTM-PPP98XL6'
};
const defaultProps = {
	"background": "--color-darkL1",
	"quarkly-title": "Footer",
	"padding": "80px 0 125px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-padding": "0 0px 0 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "46%",
			"display": "flex",
			"lg-width": "70%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--lead",
			"color": "--light",
			"letter-spacing": "1px",
			"children": "Disclaimer",
			"as": "h2"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"lg-max-width": "640px",
			"children": "The information provided on the Bos Balance website is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site. Your use of the site and your reliance on any information on the site is solely at your own risk."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "46%",
			"display": "flex",
			"lg-width": "70%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--lead",
			"color": "--light",
			"letter-spacing": "1px",
			"children": "Privacy Policy",
			"as": "h2"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"lg-max-width": "640px",
			"children": <>
				At Bos Balance, we value your privacy and are committed to protecting your personal data. Our privacy policy outlines the types of information we collect, how it's used, and the measures we take to protect your data. We comply with the General Data Protection Regulation (GDPR) and other Dutch data protection laws. For more detailed information, please view our{" "}
				<Link href="/privacy-policy" color="--light">
					Privacy Policy
				</Link>
				.
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "16px 0",
			"justify-content": "flex-end",
			"sm-flex-direction": "column",
			"display": "flex",
			"lg-justify-content": "flex-start",
			"lg-display": "flex"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 0",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"align-content": "start",
			"grid-gap": "15px 0"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"href": "/about",
			"children": "ABOUT"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/services",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "SERVICES"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/contact",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"children": "CONTACT"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/privacy-policy",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"children": "PRIVACY"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/terms-and-conditions",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"children": "T&C"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-color": "#232a44",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column",
			"lg-padding": "40px 0px 0px 0px",
			"align-items": "flex-end",
			"height": "auto",
			"md-align-items": "flex-start",
			"sm-text-align": "center"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 25px 0px",
			"children": "© 2024 BOS BALANCE BV. All rights reserved."
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "none",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"md-align-self": "flex-start"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTwitterSquare,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaGithub,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaYoutube,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "white",
			"transition": "background-color 1s ease 0s"
		}
	},
	"Creator": {
		"props": {
			"margin": "20px 0 0 0",
			"justify-content": "flex-start",
			"align-self": "flex-end",
			"sm-align-self": "center"
		}
	}
};
export const sendEvent = args => {
	TagManager.dataLayer(args);
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	useEffect(() => {
		TagManager.initialize(tagManagerArgs);
	}, []);
	return <Section {...rest}>
		<Override slot="SectionContent" justify-content="flex-start" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
					<Link {...override("link4")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box5")}>
			<Text {...override("text4")} />
			<Box {...override("box6")}>
				<LinkBox {...override("linkBox")}>
					<Icon {...override("icon")} />
				</LinkBox>
				<LinkBox {...override("linkBox1")}>
					<Icon {...override("icon1")} />
				</LinkBox>
				<LinkBox {...override("linkBox2")}>
					<Icon {...override("icon2")} />
				</LinkBox>
			</Box>
		</Box>
		<WavedropperSignature {...override('Creator')} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;